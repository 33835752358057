import { Component, OnDestroy, OnInit } from '@angular/core';
import {TerminalService} from 'primeng/components/terminal/terminalservice';
import {Subscription} from 'rxjs';
import {ClassroomComponent} from '../classroom/classroom.component';


@Component({
  selector: 'app-learning-module-pwd',
  templateUrl: '../classroom/classroom.component.html',
  styleUrls: ['../classroom/classroom.component.scss'],
  providers: [ TerminalService ]
})

export class LearningModulePwdComponent extends ClassroomComponent implements OnInit {
  moduleTitle = '1.1 - Current Working Directory';
  learningContent = 'To find out where you are in the file system, ' +
    'you can use the \'<b><u>pwd</u></b>\' command to print the current working directory. The top level in the ' +
    'directory is called the \'root\' directory. The root directory is represented by \'/\', whereas a lower level ' +
    'directory will include the \'/\' symbol followed by the full directory path - for example, typing this command in ' +
    'the \'home\' directory would print \'/home\'. An easy ' +
    'way to remember this command is that it <b><u>p</u></b>rints the <b><u>w</u></b>orking <b><u>d</u></b>irectory. ' +
    'Try this command in the prompt below.';
  exerciseText = 'Type \'pwd\' in the terminal window.';

  ngOnInit() {
  }

}
