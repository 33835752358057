import { Component, OnDestroy, OnInit } from '@angular/core';
import {TerminalService} from 'primeng/components/terminal/terminalservice';
import {Subscription} from 'rxjs';
import {ClassroomComponent} from '../classroom/classroom.component';


@Component({
  selector: 'app-learning-module-navigation',
  templateUrl: '../classroom/classroom.component.html',
  styleUrls: ['../classroom/classroom.component.scss'],
  providers: [ TerminalService ]
})
export class LearningModuleNavigationComponent extends ClassroomComponent implements OnInit {
  moduleTitle = '1 - Navigation';
  learningContent = 'One of the most important concepts when using ' +
    'the command line is navigation. Because the command line is ' +
    'not represented graphically, you’ll need to use commands to ' +
    'navigate through the file hierarchy. Files in Linux are ' +
    'structured much like they are in other systems- they are ' +
    'organized into ‘folders’ known as directories.';
  exerciseText = 'To begin the Navigation tutorial, choose a lesson from the sidebar or ' +
    '<a href="/navigation/pwd">click here to begin the Current Working Directory lesson.</a>';

  ngOnInit() {
  }

}
