import { Injectable } from '@angular/core';
import { PathToolsService } from './path-tools.service';
import { FilesystemConfigService } from './filesystem-config.service';
import { StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class FilesystemService {
  constructor(private pathTools: PathToolsService,
              private config: FilesystemConfigService,
              private storage: StorageService) {
    if (!storage.getItem(this.config.directorySeparator + '_dir')) {
      storage.setItem(config.directorySeparator + '_dir', '_dir');
    }
  }
  _currentPath = this.config.directorySeparator;


  path(path?: string) {
    if (path === '..') {
      this._currentPath = this.pathTools.directoryUp(this._currentPath);
    } else if (path && !this.pathTools.isDirNameValid(path)) {
      return 'The directory name is not valid';
    } else if (path) {
      const dirkey = this.pathTools.combine(this._currentPath, path, '_dir');
      if (!this.storage.getItem(dirkey)) {
        return 'The directory \'' + path + '\' does not exist.';
      }
      this._currentPath = this.pathTools.combine(this._currentPath, path);
    }
    return this._currentPath;
  }

  setPath(path?: string) {
    if (path === '..') {
      this._currentPath = this.pathTools.directoryUp(this._currentPath);
    } else if (path && !this.pathTools.isDirNameValid(path)) {
      return 'The directory name is not valid';
    } else if (path) {
      const dirkey = this.pathTools.combine(this._currentPath, path, '_dir');
      if (!this.storage.getItem(dirkey)) {
        return 'The directory \'' + path + '\' does not exist.';
      }
      this._currentPath = this.pathTools.combine(this._currentPath, path);
    }
    return '';
  }

  list() {
    const result = {
      directories: [],
      files: []
    };
    if (this._currentPath !== this.config.directorySeparator) {
      result.directories.push('..');
    }
    for (let i = 0; i < window.localStorage.length; i++) {
      if (this.pathTools.isFileOfPath(this._currentPath, window.localStorage.key(i))) {
        result.files.push(this.pathTools.getPathItemName(window.localStorage.key(i)));
      } else if (this.pathTools.isDirectoryOfPath(this._currentPath, window.localStorage.key(i))) {
        result.directories.push(this.pathTools.getPathItemName(window.localStorage.key(i)));
      }
    }
    result.directories.sort();
    result.files.sort();
    return result;
  }

  existsDir(path, failIfNotExist) {
    if (!this.pathTools.isDirNameValid(path)) {
      return 'The directory name is not valid';
    }

    const dirkey = this.pathTools.combine(this._currentPath, path, '_dir');
    const exists = this.storage.getItem(dirkey);
    if (!exists && failIfNotExist) {
      return 'The directory does not exist.';
    }
    return exists;
  }

  createDir(path: string) {
    const self = this;
    if (!this.pathTools.isDirNameValid(path)) {
      return 'The directory name is not valid';
    }
    if (!this.pathTools.isDirNameValid(this.pathTools.getPathItemName(path))) {
      return 'Invalid directory name';
    }
    if (self.existsDir(path, false)) {
      return 'The directory already exists.';
    } else {
      const dirkey = this.pathTools.combine(this._currentPath, path, '_dir');
      this.storage.setItem(dirkey, '_dir');
    }
  }

  removeDir(path: string) {
    const self = this;
    console.log('Remove dir: ' + path + ' on: ' + this._currentPath);
    if (!this.pathTools.isDirNameValid(path)) {
      return 'The directory name is not valid';
    }
    if (self.existsDir(path, true)) {
      const dirkey = this.pathTools.combine(this._currentPath, path, '_dir');
      path = this.pathTools.combine(this._currentPath, path);
      console.log('Full path: ' + path);
      const keys = [];
      for (const key in this.storage) {
        if (key.length >= path.length) {
          const s = key.substr(0, path.length);
          if (s === path) {
            keys.push(key);
            console.log('Remove: ' + key);
            continue;
          }
        }
        console.log('Skip: ' + key);
      }
      this.storage.removeItem(dirkey);
      for (let i = 0; i < keys.length; i++) {
        this.storage.removeItem(keys[i]);
      }
    }
  }

  writeFile(name: string, content) {
    if (!this.pathTools.isFileNameValid(name)) {
      return 'Invalid file name';
    }
    if (!content) {
      return 'No content has been passed';
    }

    const filekey = this.pathTools.combine(this._currentPath, name);
    this.storage.setItem(filekey, content);
  }

  appendToFile(name: string, content) {
    if (!this.pathTools.isFileNameValid(name)) {
      return 'Invalid file name';
    }
    if (!content) {
      return 'No content has been passed';
    }

    const filekey = this.pathTools.combine(this._currentPath, name);
    const prevcontent = this.storage.getItem(filekey);
    this.storage.setItem(filekey, (prevcontent ? prevcontent + '\n' : '') + content);
  }

  deleteFile(name: string) {
    if (!this.pathTools.isFileNameValid(name)) {
      return 'Invalid file name';
    }
    const filekey = this.pathTools.combine(this._currentPath, name);
    if (!this.storage.getItem(filekey)) {
      return 'The file does not exist';
    }
    this.storage.removeItem(filekey);
  }

  readFile(name: string) {
    if (!this.pathTools.isFileNameValid(name)) {
      return 'Invalid file name';
    }

    const filekey = this.pathTools.combine(this._currentPath, name);
    const content = this.storage.getItem(filekey);
    if (!content) {
      return 'The file does not exist';
    }
    return content;
  }

}
