import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TerminalModule } from 'primeng/terminal';

import { AppComponent } from './app.component';
import { ClassroomComponent } from './classroom/classroom.component';
import { LearningModuleNavigationComponent } from './learning-module-navigation/learning-module-navigation.component';
import { AppRoutingModule } from './/app-routing.module';
import { LearningModulePwdComponent } from './learning-module-pwd/learning-module-pwd.component';
import { LearningModuleCdComponent } from './learning-module-cd/learning-module-cd.component';
import { LearningModuleLsComponent } from './learning-module-ls/learning-module-ls.component';
import { LearningModuleNavigationExercisesComponent } from './learning-module-navigation-exercises/learning-module-navigation-exercises.component';
import { LearningModuleIntroComponent } from './learning-module-intro/learning-module-intro.component';

@NgModule({
  declarations: [
    AppComponent,
    ClassroomComponent,
    LearningModuleNavigationComponent,
    LearningModulePwdComponent,
    LearningModuleCdComponent,
    LearningModuleLsComponent,
    LearningModuleNavigationExercisesComponent,
    LearningModuleIntroComponent
  ],
  imports: [
    BrowserModule,
    TerminalModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
