import { Injectable } from '@angular/core';
import { FilesystemService } from './filesystem.service';

@Injectable({
  providedIn: 'root'
})
export class CommandsService {

  constructor(private fs: FilesystemService) { }

  pwdCommand = function() {
    const self = this;
    return self.fs.path();
  };

  mkdirCommand = function (path: string) {
    const self = this;
    if (!path) {
      return 'A directory name is required';
    }
    self.fs.createDir(path);
    return '';
  };

  cdCommand = function (path: string) {
    const self = this;
    if (!path) {
      return 'A directory name is required';
    }
    return self.fs.setPath(path);
  };

  lsCommand = function () {
    const self = this;
    const l = self.fs.list();
    const output = [];

    for (let i = 0; i < l.directories.length; i++) {
      output.push('\t\t' + l.directories[i]);
    }
    for (let i = 0; i < l.files.length; i++) {
      output.push('\t\t' + l.files[i]);
    }
    const repl = ',';
    const regex = new RegExp(repl, 'g');
    return output.toString().replace(regex, '');
  };

  touchCommand = function (name: string) {
    const self = this;
    self.fs.writeFile(name, 'test file.');
    return '';
  };
}
