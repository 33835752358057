import { Component, OnInit } from '@angular/core';
import {TerminalService} from 'primeng/components/terminal/terminalservice';
import {Subscription} from 'rxjs';
import {ClassroomComponent} from '../classroom/classroom.component';
import { CommandsService } from '../commands.service';


@Component({
  selector: 'app-learning-module-navigation-exercises',
  templateUrl: '../classroom/classroom.component.html',
  styleUrls: ['../classroom/classroom.component.scss'],
  providers: [ TerminalService ]
})
export class LearningModuleNavigationExercisesComponent extends ClassroomComponent implements OnInit {
  moduleTitle = '1.4 - Navigation Exercises';
  currentExercise = 1;
  learningContent = 'In these exercises, you\'ll practice combining the commands from the Navigation lessons.' +
    'You can revisit the lessons using the side navigation menu or click the link below to review the commands from ' +
    'the previous lessons.';
  exerciseText = 'In the home directory, there are several folders that have been created already. ' +
    'They each contain various files. Explore the directories and subdirectories until you find the ' +
    'file eg.txt.';
  helpLink = true;
  helpText = 'The commands you\'ll need are: <br/>' +
    '<b>pwd</b> - prints the current working directory. <br/>' +
    '<b>ls</b> - lists the contents of the current directory separated by spaces.<br/>' +
    '<b>cd [directory]</b> - changes the current directory to the named directory. To return to the ' +
    'parent directory, you can enter \'cd ..\'<br/>' +
    'Example usage: \'cd desktop\'';

  changeExercise(): void {
      const self = this;
      self.currentExercise = 2;
      self.exerciseText = 'Find lesson1.txt in the filesystem.';
      self.exerciseSolved = false;
      self.userFeedback = '';
  }

  ngOnInit() {

  }
}
