import { Component } from '@angular/core';
import { TerminalModule } from 'primeng/terminal';
import { StorageService} from './storage.service';
import { FilesystemConfigService } from './filesystem-config.service';
import { CommandsService } from './commands.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'egShell';

  constructor(private storage: StorageService,
              private fs: FilesystemConfigService,
              private cmd: CommandsService) {
    if (!storage.getItem(fs.directorySeparator + '_dir')) {
      storage.setItem(fs.directorySeparator + '_dir', '_dir');
    }
    this.cmd.mkdirCommand('NavigationExample');
    this.cmd.mkdirCommand('user');
    this.cmd.cdCommand('user');
    this.cmd.mkdirCommand('files');
    this.cmd.touchCommand('lesson1.txt');
    this.cmd.cdCommand('..');
    this.cmd.mkdirCommand('desktop');
    this.cmd.mkdirCommand('documents');
    this.cmd.cdCommand('documents');
    this.cmd.mkdirCommand('files');
    this.cmd.cdCommand('files');
    this.cmd.touchCommand('eg.txt');
    this.cmd.cdCommand('..');
    this.cmd.cdCommand('..');
  }
}
