import { Component, OnDestroy, OnInit } from '@angular/core';
import {TerminalService} from 'primeng/components/terminal/terminalservice';
import {Subscription} from 'rxjs';
import {ClassroomComponent} from '../classroom/classroom.component';


@Component({
  selector: 'app-learning-module-cd',
  templateUrl: '../classroom/classroom.component.html',
  styleUrls: ['../classroom/classroom.component.scss'],
  providers: [ TerminalService ]
})

export class LearningModuleCdComponent extends ClassroomComponent implements OnInit{
  moduleTitle = '1.3 - Changing Current Directory';
  learningContent = 'To change directories, you can use the \'<b><u>cd</u></b>\' command. ' +
    'This command must be followed by the pathname of the desired directory. ' +
    'There are several ways to accomplish this. You can use an absolute ' +
    'pathname or a relative pathname. An example of this ' +
    'is \'cd NavigationExample/\'. If you run this command from your home ' +
    'directory, your current working directory will become the NavigationExample ' +
    'folder in the home directory. To return to the home directory, you can use the command ' +
    '\'cd ..\'. This will allow you go up a level in the directory structure. An easy way ' +
    'to remember this command is that it allows you to <b><u>c</u></b>hange <b><u>d</u></b>irectories.';
  exerciseText = 'Type \'cd NavigationExample\' in the terminal window.';

  ngOnInit() {
  }

}
