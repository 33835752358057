import { Injectable } from '@angular/core';
import { FilesystemConfigService } from './filesystem-config.service';

@Injectable({
  providedIn: 'root'
})

export class PathToolsService {
  fileNameValidator = /^[\w_.\-]+$/;
  dirNameValidator = /^[\w_\-]+$/;

  constructor(private config: FilesystemConfigService) { }

  isAbsolute(path: string) {
    if (!path || path.length < this.config.directorySeparator.length) {
      return false;
    }
    return path.substring(0, this.config.directorySeparator.length) === this.config.directorySeparator;
  }

  addDirectorySeparator(path: string) {
    if (path.substr(path.length - this.config.directorySeparator.length,
      this.config.directorySeparator.length) !== this.config.directorySeparator) {
      path += this.config.directorySeparator;
    }
    return path;
  }

  addRootDirectorySeparator(path: string) {
    if (!this.isAbsolute(path))
      return this.config.directorySeparator + path;
    return path;
  }

  combine(...args: any[]) {
    const self = this;
    let result = '';
    for (let i = 0; i < arguments.length; i++) {

      let arg = arguments[i];

      if (i !== 0 && self.isAbsolute(arg)) {
        return 'When combining a path, only the first element can an absolute path.';
      } else if (i === 0) {
        arg = self.addRootDirectorySeparator(arg);
      }
      if (i !== arguments.length -1) {
        arg = self.addDirectorySeparator(arg);
      }

      result += arg;
    }
    return result;
  }

  directoryUp(path: string) {
    const self = this;
    if (path === this.config.directorySeparator) {
      return path;
    }
    let parts = path.split(this.config.directorySeparator);
    let count = 1;
    if (parts[parts.length - 1] === '') {
      count = 2;
    }

    for (let i = 0; i < count; i++) {
      parts.pop();
    }

    if (parts[0] === '') {
      parts = parts.slice(1);
    }
    if (!parts.length) {
      return this.config.directorySeparator;
    }

    return self.combine.apply(self, parts);
  }

  isFileOfPath(basePath: string, path: string) {
    const self = this;
    if (path.substr(0, basePath.length) === basePath) {
      let sp = path.substr(basePath.length);
      if (self.isAbsolute(sp) && sp.indexOf(this.config.directorySeparator) === sp.lastIndexOf(this.config.directorySeparator)) {
        sp = sp.substr(this.config.directorySeparator.length);
        return sp !== '_dir';
      } else {
        return sp.indexOf(this.config.directorySeparator) === -1 && sp !== '_dir';
      }
    }
    return false;
  }

  isDirectoryOfPath(basePath: string, path: string) {
    if ((path.substr(0, basePath.length) === basePath)
      && ((basePath.length === 1) || (path[basePath.length] === this.config.directorySeparator))) {
      const sp = path.substr(basePath.length);
      if (sp.length > 5) {
        const sp2 = sp.substr(0, sp.length - 5);
        if (sp2 + '/_dir' === sp) {
          const pos = sp2.indexOf('/');
          return !!sp && (pos === -1 || pos === 0);
        }
      }
    }
    return false;
  }

  getPathItemName(path: string) {
    const parts = path.split(this.config.directorySeparator);
    const last = parts[parts.length - 1];
    if (last === '_dir') {
      if (parts.length >= 3) {
        return parts[parts.length - 2];
      } else {
        return this.config.directorySeparator;
      }
    } else if (last === '') {
      return this.config.directorySeparator;
    } else {
      return last;
    }
  }

  isFileNameValid(name: string) {
    return !!name && name[0] !== '_' && !!name.match(this.fileNameValidator);
  }

  isDirNameValid(name: string) {
    return !!name && name[0] !== '_' && !!name.match(this.dirNameValidator);
  }

}
