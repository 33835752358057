import { Component, OnInit } from '@angular/core';
import {TerminalService} from 'primeng/components/terminal/terminalservice';
import {Subscription} from 'rxjs';
import {ClassroomComponent} from '../classroom/classroom.component';

@Component({
  selector: 'app-learning-module-intro',
  templateUrl: '../classroom/classroom.component.html',
  styleUrls: ['../classroom/classroom.component.scss'],
  providers: [ TerminalService ]
})
export class LearningModuleIntroComponent extends ClassroomComponent implements OnInit {
  moduleTitle = '0 - Introduction';
  learningContent = 'The <b>shell</b>, also known as the terminal or the command line, provides ' +
    'a way to interact with a computer without using a graphical interface. Some operating systems, ' +
    'such as Linux, do not have a graphical interface and require the use of the shell to do all ' +
    'tasks. Other operating systems, such as Windows and Apple OS, have a graphical interface but ' +
    'still allow the user to use the shell to accomplish tasks. This tutorial provides a simulated ' +
    'shell that currently offers a subset of the commands that a Linux machine would offer as well ' +
    'as descriptions of commands and their use. Type \'<b><u>help</u></b>\' in the terminal at any ' +
    'time to see a list of available commands.';
  exerciseText = 'To begin learning, choose a lesson from the sidebar or ' +
    '<a href="/navigation">click here to begin the Navigation lesson.</a>';

  ngOnInit() {
  }

}
