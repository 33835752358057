import { Component, OnDestroy, OnInit } from '@angular/core';
import {TerminalService} from 'primeng/components/terminal/terminalservice';
import {Subscription} from 'rxjs';
import {ClassroomComponent} from '../classroom/classroom.component';


@Component({
  selector: 'app-learning-module-ls',
  templateUrl: '../classroom/classroom.component.html',
  styleUrls: ['../classroom/classroom.component.scss'],
  providers: [ TerminalService ]
})

export class LearningModuleLsComponent extends ClassroomComponent implements OnInit {
  moduleTitle = '1.2 - Contents of Directory';
  learningContent = 'To list the contents of the current directory, ' +
    'you can use the \'<b><u>ls</u></b>\' command. This command will print a list of all ' +
    'files and folders in the current directory separated ' +
    'by spaces. Typically files will be printed with their extension, like \'textfile.txt\'. ' +
    'Directories will typically be printed with no extension, like \'directoryName\'. ' +
    'If you type this command below, the list you\'ll see will only contain directories. As you explore ' +
    'these directories later in the tutorial, you\'ll find both files and directories in the filesystem.' +
    'Any easy way to remember the \'<b><u>ls</u></b>\' command is ' +
    'that it is a \'<b><u>l</u></b>i<b><u>s</u></b>t\' of the directory contents. Try this in the command prompt below.';
  exerciseText = 'Type \'ls\' in the terminal window.';

  ngOnInit() {
  }

}
