import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  getItem(item) {
    return window.localStorage.getItem(item);
  }

  setItem(data, string) {
    return window.localStorage.setItem(data, string);
  }

  removeItem(item) {
    return window.localStorage.removeItem(item);
  }
}
