import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClassroomComponent } from './classroom/classroom.component';
import { LearningModuleNavigationComponent } from './learning-module-navigation/learning-module-navigation.component';
import { LearningModuleCdComponent} from './learning-module-cd/learning-module-cd.component';
import { LearningModuleLsComponent} from './learning-module-ls/learning-module-ls.component';
import { LearningModulePwdComponent} from './learning-module-pwd/learning-module-pwd.component';
import { LearningModuleNavigationExercisesComponent} from './learning-module-navigation-exercises/learning-module-navigation-exercises.component';
import {LearningModuleIntroComponent} from './learning-module-intro/learning-module-intro.component';

const routes: Routes = [
  { path: '', redirectTo: '/intro', pathMatch: 'full' },
  { path: 'intro', component: LearningModuleIntroComponent },
  { path: 'navigation', component: LearningModuleNavigationComponent },
  { path: 'navigation/pwd', component: LearningModulePwdComponent },
  { path: 'navigation/ls', component: LearningModuleLsComponent },
  { path: 'navigation/cd', component: LearningModuleCdComponent },
  { path: 'navigation/exercises', component: LearningModuleNavigationExercisesComponent }
  // { path: '**', redirectTo: '/intro'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
