import {Component, OnDestroy, OnInit} from '@angular/core';
import {TerminalService} from 'primeng/components/terminal/terminalservice';
import {Subscription} from 'rxjs';
import { FilesystemService } from '../filesystem.service';
import { CommandsService } from '../commands.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.scss'],
  providers: [TerminalService, CommandsService]
})
export class ClassroomComponent implements OnInit {
  title = 'egShell';
  moduleTitle = '';
  learningContent = 'Tutorial content will go here. This will include an example command' +
    'and how to use it, as well as some background information about the command. ' +
    'Type \'date\' in the terminal below to see an example of future functionality.';
  exerciseText = '';
  userFeedback = '';
  currentExercise = -1;
  exerciseSolved = false;
  helpLink = false;
  showHelp = false;
  helpText = '';

  subscription: Subscription;

  constructor(private terminalService: TerminalService, public cmd: CommandsService, private router: Router) {
    this.terminalService.commandHandler.subscribe(command => {
      const splitCommand = command.split(' ');
      switch (splitCommand[0].toLowerCase()) {
        case 'date': {
          this.userFeedback = '';
          const response = new Date().toDateString();
          this.terminalService.sendResponse(response);
          break;
        }
        case 'help': {
          this.userFeedback = '';
          const response = 'Available commands: \t\t \'pwd\' \t\t \'ls\' \t\t \'cd [directory]\'';
          this.terminalService.sendResponse(response);
          break;
        }
        case 'pwd': {
          this.userFeedback = '';
          const response = this.cmd.pwdCommand();
          this.terminalService.sendResponse(response);
          if (this.router.url === '/navigation/pwd') {
            this.userFeedback = 'Nice job! You can continue practicing this command or ' +
              '<a href="/navigation/ls">move on to the next lesson.</a><br/><br/>';
          }
          this.userFeedback += 'Current working directory: ' + response;
          break;
        }
        case 'ls': {
          this.userFeedback = '';
          const response = this.cmd.lsCommand();
          if (this.router.url === '/navigation/ls') {
            this.userFeedback = 'Nice job! You can continue practicing this command or ' +
              '<a href="/navigation/cd">move on to the next lesson.</a><br/><br/>';
          }
          if ((this.router.url === '/navigation/exercises') && (response.indexOf('eg.txt') !== -1) && (this.currentExercise === 1)) {
            this.userFeedback = 'Nice job! You found the file! <br/><br/>';
            this.exerciseSolved = true;
          }
          if ((this.router.url === '/navigation/exercises') && (response.indexOf('lesson1.txt') !== -1) && (this.currentExercise === 2)) {
            this.userFeedback = 'Nice job! You found the file! <br/><br/>';
          }
          this.terminalService.sendResponse(response);
          this.userFeedback += 'Contents of current directory: ' + response;
          break;
        }
        case '1s' || 'is': {
          this.userFeedback = '';
          this.userFeedback = 'Unknown command: ' + command + '. Did you mean \'ls\'?';
          const response = 'Unknown command: ' + command;
          this.terminalService.sendResponse(response);
          break;
        }
        case 'is': {
          this.userFeedback = '';
          this.userFeedback = 'Unknown command: ' + command + '. Did you mean \'ls\'?';
          const response = 'Unknown command: ' + command;
          this.terminalService.sendResponse(response);
          break;
        }
        case 'mkdir': {
          this.userFeedback = '';
          const response = this.cmd.mkdirCommand(splitCommand[1]);
          this.terminalService.sendResponse(response);
          break;
        }
        case 'cd': {
          this.userFeedback = '';
          let response = '';
          if (splitCommand[1]) {
            let directory = splitCommand[1];
            if (directory[directory.length - 1] === '/') {
              directory = directory.substring(0, directory.length - 1);
            }
            response = this.cmd.cdCommand(directory);
            if ((this.router.url === '/navigation/cd') && (directory === 'NavigationExample')) {
              this.userFeedback = 'Nice job! You can continue practicing this command or ' +
                '<a href="/navigation/exercises">move on to the lesson exercises.</a><br/><br/>';
            }
            if (this.router.url === '/navigation/exercises') {
              this.userFeedback = 'To find out if this directory contains the .txt file, try using ' +
                'a command from the Navigation lessons to list the contents of this directory.';
            }
          } else {
            response = this.cmd.cdCommand('');
            this.userFeedback = 'You must enter a directory name when you use the \'cd\' command.'
          }
          this.terminalService.sendResponse(response);
          break;
        }
        case 'touch': {
          this.userFeedback = '';
          const response = this.cmd.touchCommand(splitCommand[1]);
          this.terminalService.sendResponse(response);
          break;
        }
        default: {
          this.userFeedback = '';
          const response = 'Unknown command: ' + command;
          this.terminalService.sendResponse(response);
          this.userFeedback = response;
          break;
        }
      }
    });
  }

  ngOnInit() {
  }

  // ngOnDestroy() {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  // }

}
